import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'src/services/notifications.service';
import { CrudService } from '../services/crud.service';
import { requestUrl } from '../services/adminReqUrl.const';
import { cusRequestUrl } from '../services/customerReqUrl.const';
import { ToastrService } from 'ngx-toastr';
import { CustomerBusinessService } from './customer-business.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],

})
export class CustomerComponent implements OnInit {
  isDesktopMenuOpen: boolean = true;
  ismenuShow: boolean = true;
  credit: boolean = false;
  myfile: boolean = false;
  dealerMgmt: boolean = false;
  support: boolean = false;
  showdrop: boolean = false;
  users: any;
  role_title: any;
  windowWidth: any;
  image: string = '';
  page: number = 1;
  pageSize: number = 10;
  unseenNotiCount: number = 0;
  notificationList: any;
  cartItem: any;
  totalCartItem: number = 0;
  permissions: any;
  constructor(private router: Router, public notification: NotificationsService,
    private _titleservice: Title, private crud: CrudService, private toastr: ToastrService,
    private customerBservice: CustomerBusinessService
  ) {
    this._titleservice.setTitle('Dashboard');
  }

  ngOnInit(): void {
    this.notification.initSocket();
    this.ismenuShow = (window.screen.width <= 767) ? false : true;
    this.users = JSON.parse(localStorage.getItem('userdetails') || '');
    this.role_title = this.users.role;
    this.image = this.users.image;
    this.notification.updateImg.subscribe(res => {
      if (res) {
        this.crud.getRequest(cusRequestUrl.getCustomerProfile).then((res: any) => {
          localStorage.setItem('userdetails', JSON.stringify(res.data));
          this.users = JSON.parse(localStorage.getItem('userdetails') || '');
          this.image = this.users.image;
        })
        this.users = JSON.parse(localStorage.getItem('userdetails') || '');
        this.image = this.users.image;
      }
    });
    this.notification.tunningPoints.subscribe(res => {
      if (res) {
        this.users = JSON.parse(localStorage.getItem('userdetails') || '');
      }
    });
    this.notification.cartItem.subscribe(res => {
      if (res) {
        this.getCustomerCartDetail();
      }
    });
    this.notification.onMessageUser.subscribe((res: any) => {
      if (res && res.type === 'new_notification') {
        this.unseenNotiCount = res.data.un_seen_message_count
        this.customerBservice.broadCastNotification(this.unseenNotiCount)
        this.notificationList.unshift(res.data)
      }
      else if (res && res.type === 'file_submission_completed') {
        if (res.uuid == this.users.uuid) {
          this.unseenNotiCount = 1;
          this.customerBservice.broadCastNotification(this.unseenNotiCount)
          let body = {
            message: res.message,
            type: res.type,
            id: res.ids,
            created_at: res.created_at
          }
          this.notificationList.unshift(body)
        }
      }
      else if (res && res.type === 'ticket_create_notification_customer') {
        this.unseenNotiCount = 1;
        this.customerBservice.broadCastNotification(this.unseenNotiCount)
        let body = {
          message: res.message,
          type: res.type,
          id: res.ids,
          created_at: res.created_at
        }
        this.notificationList.unshift(body)
      }
      else if (res && res.type === 'user_detail_update') {
        localStorage.setItem('userdetails', JSON.stringify(res.data));
      }
      else if (res && res.type === 'customer_key_update') {
        this.getTypeCount();
      }
    });
    this.getNotification();
    this.getCustomerCartDetail();
    this.getTypeCount();

  }
  getCustomerCartDetail() {
    this.crud.getRequest(cusRequestUrl.customerCart).then((res: any) => {
      if (res) {
        this.cartItem = res.data
        var i = 0;
        this.cartItem.forEach((element:any) => {
          if(element.quantity > 0){
            i = i+element.quantity;
          }
        });
        this.totalCartItem = i;
      }
    })
  }

  logout() {
    localStorage.removeItem('userdetails');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('dashBoardPermissions');
    localStorage.removeItem('ticketsharedata');
    localStorage.removeItem('fileRequestData');
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  toggleAnimations() {
    this.credit = !this.credit;
  }

  loadZoho() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.id = 'zsiqchat';
    script.innerHTML = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "e67078e4a07a4daefd28a0fe35de3ebc2b01fb3f445fa668c5267b2e9e699950670d7e98b5527ce850ede6e955540cdb", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.windowWidth = window.innerWidth;
    this.ismenuShow = (window.screen.width <= 767) ? false : true;
  }

  toggleMenu() {
    this.ismenuShow = !this.ismenuShow;
  }
  toggleMenuOnProfile() {
    this.windowWidth = window.innerWidth;
    if (window.screen.width <= 767)
      this.ismenuShow = false;
  }
  getNotification() {
    this.crud.getRequest(`${requestUrl.getNotification}${this.page}&page_size=${this.pageSize}`).then((res: any) => {
      if (res) {
        this.notificationList = res.data.results;
        this.unseenNotiCount = res.data.un_seen_message_count;
        this.customerBservice.broadCastNotification(this.unseenNotiCount)
      }
    })
  }
  redirectTo(data: any) {
    if (data.type == 'file_submission_completed')
      this.router.navigateByUrl(`/file-service/file-services`)
    else if (data.type == 'ticket_create_notification_customer')
      this.router.navigateByUrl(`/support/view-ticket/${data?.id}/${data?.id.slice(2, 5)}`)
    else if (data.ticket) {
      this.router.navigateByUrl(`/support/view-ticket/${data.ticket.ids}/${data.ticket.id}`)
    }
  }
  readNotification() {
    this.crud.postRequest(requestUrl.readNotification, {}).then((res: any) => {
      if (res)
        this.unseenNotiCount = 0;
      this.customerBservice.broadCastNotification(this.unseenNotiCount)
    })
  }
  checkoutCart() {
    this.router.navigateByUrl('/credit/checkout')
  }

  toggleTopMenu() {
    this.isDesktopMenuOpen = !this.isDesktopMenuOpen;

  }

  fadeIn(): any {
    throw new Error('Function not implemented.');
  }
  getTypeCount() {
    this.crud.getRequest(cusRequestUrl.getCustomerProfile).then((res: any) => {
      if(res?.data) {
        localStorage.setItem('userdetails', JSON.stringify(res.data));
        this.users = JSON.parse(localStorage.getItem('userdetails') || '');
      }
    })
  }
}





