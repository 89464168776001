import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page.component';
import { RouterModule, Routes } from '@angular/router';

const routes:Routes = [
  {path:'', component:LandingPageComponent},
  {path:'about', loadChildren:() => import("./about-us/about-us.module").then(m => m.AboutUsModule) },
  {path:'privacy', loadChildren:() => import("./privacy-policy/privacy.policy.module").then(m => m.PrivacyPolicyModule)},
  {path:'terms-and-conditions', loadChildren:() => import("./terms-and-conditions/terms-and-conditions.module").then(m => m.TermsAndConditionsModule) },
  {path:'viewArticle', loadChildren:() => (import("./view-article/view-article.module").then(m => m.ViewArticleModule)) },
  {path:'about-viezu-files', loadChildren:() => import("./about-files/about-files.module").then(m => m.AboutFilesModule) },
]

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
})
export class LandingPageModule { }
