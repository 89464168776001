<section class="customer-dashboard">
    <div class="side-bar" style="overflow-x:hidden;" [ngClass]="isDesktopMenuOpen==true?'expand':'squeeze'"
        [ngStyle]="{'display':ismenuShow?'block':'none'}" *ngIf="ismenuShow">
        <div class="logo">
            <a routerLink=""><img class="plogo" src="assets/images/remap.png" alt="logo" /></a>
            <img class="slogo" src="assets/images/icon/slogo.svg" alt="logo" />
        </div>
        <div class="menu">
            <ul class="smenu">
                <li (click)="credit=false;myfile=false;support=false">
                    <a routerLink="dashboard" routerLinkActive="active-link"
                        (click)="ismenuShow=windowWidth<767?false:true"> <img class="indicator"
                            src="assets/images/icon/dashboard.png" alt="dashboard" /><span>Dashboard</span></a>
                </li>
                <li (click)="credit=!credit;dealerMgmt=false;myfile=false;support=false;" class="cdrop">
                    <a routerLinkActive="active-link"><img class="indicator" src="assets/images/icon/credit.png"
                            alt="credit" /><span>My Credit</span><img class="icon"
                            src="assets/images/icon/right-arrow.png" alt="right arrow" /></a>
                    <ul *ngIf="credit">
                        <li *ngIf="users.role != 'DEALER'" (click)="credit=!credit;myfile=false;support=false"><a
                                routerLink="/credit/buy-credit" routerLinkActive="active-link"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/buyCredit.png" alt="buy credit" />
                                <span>Buy Credits</span></a></li>
                        <li (click)="credit=!credit;myfile=false;support=false"><a
                                routerLink="/credit/credit-transaction" routerLinkActive="active-link"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/creditTrx.png" alt="credit transction" />
                                <span>Transactions </span></a></li>
                        <li *ngIf="users.role != 'DEALER'" (click)="credit=!credit;myfile=false;support=false"><a
                                routerLink="/credit/invoice-histoy" routerLinkActive="active-link"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/invoiceHis.png" alt="invoice history" />
                                <span>Invoice History</span></a></li>
                    </ul>
                </li>
                <li (click)="myfile=!myfile;dealerMgmt=false;credit=false;support=false;" class="cdrop">
                    <a routerLinkActive="active-link"><img class="indicator" src="assets/images/icon/services.png"
                            alt="file services" /><span>My File Service</span>
                        <img class="icon" src="assets/images/icon/right-arrow.png" alt="rightarrow" /></a>
                    <ul *ngIf="myfile">
                        <li (click)="myfile=!myfile;credit=false;support=false;"><a routerLink="file-service"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/rfs.png" alt="request file services" />
                                <span>Submit File</span></a></li>
                        <li (click)="myfile=!myfile;credit=false;support=false;"><a
                                routerLink="file-service/file-services" routerLinkActive="active-link"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/fs.png" alt="file services" />
                                <span>File History</span></a></li>
                    </ul>
                </li>
                <li *ngIf="users.role == 'RESELLER'"
                    (click)="dealerMgmt=!dealerMgmt;credit=false;myfile=false;support=false;" class="cdrop">
                    <a routerLinkActive="active-link"><img class="indicator" src="assets/images/icon/customerIcon.png"
                            alt="credit" /><span>Dealer Management</span><img class="icon"
                            src="assets/images/icon/right-arrow.png" alt="right arrow" /></a>
                    <ul *ngIf="dealerMgmt">
                        <li (click)="dealerMgmt=!dealerMgmt;myfile=false;support=false"><a routerLink="sub-dealer"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/customerIcon.png" alt="buy credit" />
                                <span>Dealer</span></a></li>
                        <li (click)="dealerMgmt=!dealerMgmt;myfile=false;support=false"><a
                                routerLink="/sub-dealer/dealer-credit" routerLinkActive="active-link"
                                (click)="ismenuShow=windowWidth<767?false:true">
                                <img src="assets/images/icon/credit.png" alt="credit transction" />
                                <span>Credit </span></a></li>
                    </ul>
                </li>
                <li>
                    <a (click)="ismenuShow=windowWidth<767?false:true" routerLink="credit/shop"
                        routerLinkActive="active-link">
                        <img class="indicator" src="assets/images/icon/cart.png" alt="profile" /><span>Shop
                        </span>
                    </a>
                </li>
                <li *ngIf="users.role=='RESELLER'" (click)="dealerMgmt=false;myfile=false;support=false;">
                    <a routerLink="/report-log" routerLinkActive="active-link"
                        (click)="ismenuShow=windowWidth<767?false:true"><img class="indicator"
                            src="assets/images/icon/reportIcon.png" alt="log report" /> <span>Log Report</span></a>
                </li>
                <li *ngIf="users.role!='RESELLER'" (click)="dealerMgmt=false;myfile=false;support=false;">
                    <a routerLink="/report-log/customer" routerLinkActive="active-link"
                        (click)="ismenuShow=windowWidth<767?false:true"><img class="indicator"
                            src="assets/images/icon/reportIcon.png" alt="log report" /> <span>Log Report</span></a>
                </li>
                <li (click)="myfile=false">
                    <a routerLink="knowledgebase" routerLinkActive="active-link"
                        (click)="ismenuShow=windowWidth<767?false:true"><img class="indicator"
                            src="../../assets/images/icon/Knowledgewhite.svg" alt="dashboard" /><span>Knowledgebase</span> </a>
                </li>
                <li (click)="ismenuShow=windowWidth<767?false:true;support=!support;myfile=false;credit=false" class="cdrop">
                    <a routerLink="support" routerLinkActive="active-link"><img class="indicator"
                            src="assets/images/icon/support.png" alt="support" /> <span>Ticket</span> </a>
                </li>
                <li>
                    <a (click)="ismenuShow=windowWidth<767?false:true" routerLink="my-profile"
                        routerLinkActive="active-link">
                        <img class="indicator" src="assets/images/icon/profile.png" alt="profile" /><span>My Profile
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="top-bar" [ngClass]="isDesktopMenuOpen==true?'expand':'squeeze'">
        <div class="lefttop">
            <img (click)="toggleTopMenu()" src="../../assets/images/icon/toggleBtn.png" alt="toggle button" />
        </div>
        <!-- <div class="beta banner">
            <p>Beta Version</p>
        </div> -->
        <div class="righttop">
            <div class="myCredit">
                <div class="title leftBorderRound">
                    <div>My Credit</div>
                </div>
                <div class="block">
                    <div class="nestedHeading equalblock">File</div>
                    <div class="nestedContent">{{users.customer_credit?.file_key_credit?users.customer_credit?.file_key_credit: 0}}</div>
                </div>
                <div class="block">
                    <div class="nestedHeading ">Function</div>
                    <div class="nestedContent">{{users.customer_credit?.function_credit?users.customer_credit?.function_credit: 0}}</div>
                </div>
                <div class="block rightBorderRound">
                    <div class="nestedHeading equalblock">EVC</div>
                    <div class="nestedContent">{{users.customer_credit?.evc_credit?users.customer_credit?.evc_credit: 0 }}</div>
                </div>
            </div>
            <div *ngIf="users.role=='RESELLER'" class="myCredit">
                <div class="title leftBorderRound">
                    <div>Reseller Credit</div>
                </div>
                <div class="block">
                    <div class="nestedHeading equalblock">File</div>
                    <div class="nestedContent">{{users.reseller_dealer_credit?.file_key_credit?users.reseller_dealer_credit?.file_key_credit: 0 }}</div>
                </div>
                <div class="block rightBorderRound">
                    <div class="nestedHeading ">Function</div>
                    <div class="nestedContent">{{users.reseller_dealer_credit?.function_credit?users.reseller_dealer_credit?.function_credit: 0 }}</div>
                </div>
            </div>
            <!-- <div class="credit" (click)="toggleMenuOnProfile()">
                <div class="creditInfo">
                    <ul style="min-width: max-content;">
                        <li *ngIf="users.role=='RESELLER'" style="font-weight: 600;">Reseller Credit</li>
                        <li><a>File key credit :
                                {{users.customer_credit?.file_key_credit?users.customer_credit?.file_key_credit: 0
                                }}</a></li>
                        <li *ngIf="users.role=='BUSINESS_CUSTOMER' || users.role=='RESELLER'"><a>Functional key credit:
                                {{users.customer_credit?.function_credit?users.customer_credit?.function_credit: 0
                                }}</a></li>
                        <li *ngIf="users.role=='BUSINESS_CUSTOMER' && users.is_evc_reseller_customer == true"><a>EVC
                                Credit: {{users.customer_credit?.evc_credit?users.customer_credit?.evc_credit: 0 }}</a>
                        </li>
                        <li *ngIf="users.role=='RESELLER' && users?.reseller_dealer_credit"
                            style="font-weight: 600; margin-top: 10px;">Dealer Credit</li>
                        <li *ngIf="users.role=='RESELLER' && users?.reseller_dealer_credit"><a>File key credit :
                                {{users.reseller_dealer_credit?.file_key_credit?users.reseller_dealer_credit?.file_key_credit:
                                0 }}</a></li>
                        <li *ngIf="users.role=='RESELLER' && users?.reseller_dealer_credit"><a>Function key credit :
                                {{users.reseller_dealer_credit?.function_credit?users.reseller_dealer_credit?.function_credit:
                                0 }}</a></li>
                    </ul>
                </div>
            </div> -->
            <div (click)="checkoutCart()" class="pointer cartItem">
                <img class="notiIcon" src="assets/images/icon/scart.png" alt="shopping cart" />
                <span class="badge rounded-pill badge-notification bg-primary">{{ totalCartItem }}</span>
            </div>
            <div class="notification pointer" (click)="readNotification()">
                <img class="notiIcon" data-bs-toggle="dropdown" src="assets/images/icon/notification.png"
                    alt="notification" />
                <span data-bs-toggle="dropdown" class="badge rounded-pill badge-notification bg-primary">{{
                    unseenNotiCount }}</span>
                <ul class="dropdown-menu" style="min-width:350px; width: 450px; height: 300px; overflow-y: scroll;">
                    <li *ngIf="notificationList?.length==0" style="text-align: center;">No Notification...</li>
                    <li *ngFor="let noti of notificationList">
                        <div class="profile mb-2">
                            <img [src]="noti.sender?.image ? noti.sender?.image: 'assets/images/icon/customer-profile.png'"
                                alt="profile" />
                            <div class="notification-inner" (click)="redirectTo(noti)">
                                <div>
                                    <h6 class="mb-0">{{noti.title}}</h6>
                                    <p>{{noti.message}} </p>
                                </div>
                                <p>{{notification.formatDate(noti.created_at)}}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="profile" (click)="toggleMenuOnProfile()">
                <img [src]="image || 'assets/images/icon/customer-profile.png'" onerror="this.src = 'assets/images/icon/customer-profile.png'" alt="profile" />
                <div class="dropdown-toggle" data-bs-toggle="dropdown">
                    <p> {{users?.first_name || ''}} {{users?.last_name || ''}} </p>
                    <span> {{role_title.replace('_', ' ') | titlecase}} </span>
                    <ul class="dropdown-menu">
                        <li><a routerLink="/my-profile"><img src="assets/images/icon/account.svg" />Profile</a></li>
                        <li><a (click)="logout()"><img src="assets/images/icon/logout.svg" />Logout</a></li>
                    </ul>
                </div>
            </div>

            <div class="menu-btn" (click)="toggleMenu()" >
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L4 7" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M20 12L4 12" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M20 17L4 17" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div *ngIf="users.role !== 'RESELLER'" class="myCredit mobile">
            <div class="title leftBorderRound">
                <div>My Credit</div>
            </div>
            <div class="block">
                <div class="nestedHeading equalblock">File</div>
                <div class="nestedContent">{{users.customer_credit?.file_key_credit?users.customer_credit?.file_key_credit: 0}}</div>
            </div>
            <div class="block">
                <div class="nestedHeading ">Function</div>
                <div class="nestedContent">{{users.customer_credit?.function_credit?users.customer_credit?.function_credit: 0}}</div>
            </div>
            <div class="block rightBorderRound">
                <div class="nestedHeading equalblock">EVC</div>
                <div class="nestedContent">{{users.customer_credit?.evc_credit?users.customer_credit?.evc_credit: 0 }}</div>
            </div>
        </div>
        <div *ngIf="users.role=='RESELLER'" class="myCredit reseller mobile">
            <div>
                <div class="title leftBorderRound">
                    <div>My Credit</div>
                </div>
                <div class="block">
                    <div class="nestedHeading equalblock">File</div>
                    <div class="nestedContent">{{users.customer_credit?.file_key_credit?users.customer_credit?.file_key_credit: 0}}</div>
                </div>
                <div class="block">
                    <div class="nestedHeading ">Function</div>
                    <div class="nestedContent">{{users.customer_credit?.function_credit?users.customer_credit?.function_credit: 0}}</div>
                </div>
                <div class="block rightBorderRound">
                    <div class="nestedHeading equalblock">EVC</div>
                    <div class="nestedContent">{{users.customer_credit?.evc_credit?users.customer_credit?.evc_credit: 0 }}</div>
                </div>
            </div>
            <div>
                <div class="title leftBorderRound">
                    <div>Reseller Credit</div>
                </div>
                <div class="block">
                    <div class="nestedHeading equalblock">File</div>
                    <div class="nestedContent">{{users.reseller_dealer_credit?.file_key_credit?users.reseller_dealer_credit?.file_key_credit: 0 }}</div>
                </div>
                <div class="block rightBorderRound">
                    <div class="nestedHeading ">Function</div>
                    <div class="nestedContent">{{users.reseller_dealer_credit?.function_credit?users.reseller_dealer_credit?.function_credit: 0 }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="right-area" [ngClass]="isDesktopMenuOpen==true?'expand':'squeeze'">
        <div class="right-inner" [class.reseller]="users.role=='RESELLER'">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>